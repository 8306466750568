import React, { useState, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import AppModal from "./AppModal";
import { ErrorText } from "../atoms/UI/Text";
import styled from "@emotion/styled";
import { InputBorder } from "../atoms/UI/Inputs";
import { PrimaryButtonStyled } from "../atoms/UI/Buttons";
import PassswordForm from "../forms/PassswordForm";
import useManagerModal from "../../hooks/useManagerModal";
import PhoneModal from "./PhoneModal";
import { instance } from "../../api/instance.axios";
import { localStorageWrapper } from "../../config/storage";
import { useAppDispatch } from "../../hooks/useAppDisptach";
import { getUserInformationThunk } from "../../store/thunk/system.thunk";
import useValidAxiosError from "../../hooks/useValidAxiosError";
import { ToastContainer, toast } from "react-toastify";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { actions } from "../../store/slice/profile.slice";
import { TelegramIcons } from "../atoms/Icons";
// import { AxiosError } from "axios";
import { Link } from "react-router-dom";

interface IProps {
  clearModal: () => void;
  title: string;
}

interface IFormInput {
  login: string;
  password: string;
}

const LoginModal: React.FC<IProps> = ({ clearModal, title }) => {
  const { control, handleSubmit } = useForm<IFormInput>({
    defaultValues: {
      login: "",
      password: "",
    },
    mode: "onTouched",
  });

  const [code, setCode] = useState<string>("");
  const { fetchError, error } = useValidAxiosError();
  const loader = useTypedSelector((state) => state.profile.loading);
  const { setPhoneModal, modalInfo: modalInfoPhone } = useManagerModal();
  const dispatch = useAppDispatch();

  const onRegisterShow = useCallback(() => {
    setPhoneModal("Введите номер телефона");
  }, [setPhoneModal]);

  const onSubmit = useCallback(
    async (data: IFormInput) => {
      if (!data.login || !data.password) {
        return;
      }

      dispatch(actions.setLoading(true));
      try {
        await instance
          .post("/auth/login-step1", {
            phone: data.login,
            password: data.password,
          })
          .then(() => {
            toast.success("Код отправлен, проверьте телеграмм бот");
          });
      } catch (err: any) {
        fetchError(err);
      } finally {
        dispatch(actions.setLoading(false));
      }
    },
    [dispatch, fetchError]
  );

  const onSubmit2 = useCallback(
    async (data: IFormInput) => {
      if (!data.login || !data.password || !code) {
        return;
      }

      dispatch(actions.setLoading(true));
      try {
        const res = await instance.post("/auth/login-step2", {
          phone: data.login,
          password: data.password,
          code,
        });

        if (res) {
          console.log(res);
          localStorageWrapper.set("accessToken", (res as any).accessToken);
          localStorageWrapper.set("id", (res as any).id);
          localStorageWrapper.set("phone", (res as any).phone);
          localStorageWrapper.set("email", (res as any).email);
          dispatch(getUserInformationThunk());
          clearModal();
        }
      } catch (err: any) {
        fetchError(err);
      } finally {
        dispatch(actions.setLoading(false));
      }
    },
    [code, clearModal, dispatch, fetchError]
  );

  return (
    <>
      {modalInfoPhone && (
        <PhoneModal
          title={modalInfoPhone.title as string}
          clearModal={clearModal}
        />
      )}
      <AppModal name={"login"} onClose={clearModal}>
        <div className="title-block">{title}</div>

        <form onSubmit={handleSubmit(!code ? onSubmit : onSubmit2)}>
          <Controller
            name="login"
            control={control}
            rules={{ required: "Номер обязательно для заполнения" }}
            render={({ field, fieldState }) => (
              <>
                <InputBorder
                  {...field}
                  type="text"
                  placeholder="Номер телефона"
                  bottom="1px solid #27457C4A"
                  style={{ marginBottom: "1rem", borderRadius: "0" }}
                />
                {fieldState.error && (
                  <ErrorText style={{ margin: "0" }}>
                    {fieldState.error.message}
                  </ErrorText>
                )}
              </>
            )}
          />

          <Controller
            name="password"
            control={control}
            rules={{ required: "Пароль обязательно для заполнения" }}
            render={({ field, fieldState }) => (
              <>
                <PassswordForm field={field} />
                {fieldState.error && (
                  <ErrorText style={{ margin: "0" }}>
                    {fieldState.error.message}
                  </ErrorText>
                )}
              </>
            )}
          />

          <CodeStyled>
            <InputBorder
              type="text"
              placeholder="Код"
              bottom="1px solid #27457C4A"
              style={{ marginBottom: "1rem", borderRadius: "0" }}
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />

            <Link to={"https://t.me/alphacargoverify_bot"} target="_blank">
              <TelegramIcons />
            </Link>
            <button type="submit">
              <img
                className="img"
                src={require("../../assets/send.jpeg")}
                alt="send"
              />
            </button>
          </CodeStyled>

          <Buttons className="flex gap-1_2">
            <PrimaryButtonStyled type="submit" disabled={loader}>
              Войти
            </PrimaryButtonStyled>

            <PrimaryButtonStyled onClick={onRegisterShow} disabled={loader}>
              Регистрация
            </PrimaryButtonStyled>
          </Buttons>
        </form>
      </AppModal>

      <ToastContainer />
    </>
  );
};

const Buttons = styled.div`
  margin-top: 2rem;

  button {
    width: 50%;
  }
`;

const CodeStyled = styled.div`
  position: relative;

  input:read-only {
    cursor: pointer;
  }

  svg {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 40%;
    right: 20px;
    transform: translateY(-50%);
  }

  img {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 40%;
    right: 60px;
    transform: translateY(-50%);
    cursor: pointer;
  }

  button {
    width: 0;
    height: 0;
    border: none;
    background: transparent;
  }
`;

export default LoginModal;
