import { FC } from "react";
import { Block } from "../../atoms/UI/Block";
import { PrimaryButtonStyled } from "../../atoms/UI/Buttons";
import styled from "@emotion/styled";

interface IProps {
  successPayment: any[] | undefined;
  setPdfInvoice: any;
}

const PayBlock: FC<IProps> = ({ successPayment, setPdfInvoice }) => {
  if (successPayment?.length === 0) {
    return (
      <div className="title-block" style={{ textAlign: "center" }}>
        Пусто
      </div>
    );
  }

  return (
    <>
      {successPayment?.map((item, index) => (
        <Block
          style={{
            backgroundColor: "#94c325",
            marginTop: "2rem",
            color: "white",
            cursor: "pointer",
          }}
          key={index + 1}
        >
          <div
            className="title-block"
            onClick={() => setPdfInvoice(item.invoice)}
          >
            Номер накладной: <InvoiceSpan>{item.invoice}</InvoiceSpan>
          </div>
          <div className="subtitle-block">Дата: {item.date}</div>
          <div className="subtitle-block">Статус: {item.status}</div>
        </Block>
      ))}
    </>
  );
};

const InvoiceSpan = styled.span`
  text-decoration: underline;
  color: white!important;
`;

export default PayBlock;
