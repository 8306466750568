export function base64ToPdf(pdfBase64: string) {
    try {
      const decodedBase64 = decodeURIComponent(pdfBase64);
      if (!isValidBase64(decodedBase64)) {
        throw new Error('Invalid base64 string');
      }
      const binaryString = atob(decodedBase64);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      const blob = new Blob([bytes], { type: 'application/pdf' });
  
      return blob;
    } catch (error) {
      console.error('Error converting base64 to PDF:', error);
      return null;
    }
  }
  
  function isValidBase64(str: string) {
    const base64Regex = /^(?:[A-Z0-9+/]{4})*(?:[A-Z0-9+/]{2}==|[A-Z0-9+/]{3}=)?$/i;
    return base64Regex.test(str);
  }
  