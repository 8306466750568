import { useEffect, useMemo, useState } from "react";
import styled from "@emotion/styled";
import ProfileLayout from "../../../layouts/ProfileLayout";
import { Block, TabBarBlock } from "../../../components/atoms/UI/Block";
import { PackageIcons, PricingIcons } from "../../../components/atoms/Icons";
import { Link } from "react-router-dom";
import { PrimaryButtonStyled } from "../../../components/atoms/UI/Buttons";
import AllBlock from "../../../components/molecules/Package/AllBlock";
import NotPayBlock from "../../../components/molecules/Package/NotPayBlock";
import PayBlock from "../../../components/molecules/Package/PayBlock";
import { useAppDispatch } from "../../../hooks/useAppDisptach";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import {
  getParcelInovicePDFInformationThunk,
  getParcelsInformationThunk,
} from "../../../store/thunk/profile.thunk";
import { actions } from "../../../store/slice/profile.slice";
import LoadingModal from "../../../components/modal/LoadingModal";
import useManagerModal from "../../../hooks/useManagerModal";
import RequestDeliveryModal from "../../../components/modal/RequestDeliveryModal";
import { base64ToPdf } from "../../../utlis/base64ToPdf";

const Package = () => {
  const [activeTab, setActiveTab] = useState<"all" | "pay" | "notpay">("all");
  const dispatch = useAppDispatch();
  const parcels = useTypedSelector((state) => state.profile.percels);
  const loading = useTypedSelector((state) => state.profile.loading);
  const pdf = useTypedSelector((state) => state.profile.pdf);
  const [pdfInvoice, setPdfInvoice] = useState<string>("");
  const { modalInfo, setRequestDelivery, clearModal } = useManagerModal();

  useEffect(() => {
    dispatch(actions.setLoading(true));
    dispatch(getParcelsInformationThunk()).finally(() => {
      dispatch(actions.setLoading(false));
    });
  }, [dispatch]);

  useEffect(() => {
    if (pdfInvoice) {
      dispatch(getParcelInovicePDFInformationThunk(pdfInvoice)).finally(() => {
        setPdfInvoice("");
      });
    }
  }, [pdfInvoice, dispatch]);

  useEffect(() => {
    if (loading) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [loading]);

  const onShowModalRequest = () => {
    setRequestDelivery("Оформить заказ");
  };

  const _notPayment = useMemo(
    () => parcels.History?.filter((item) => item.payment === false),
    [parcels]
  );
  const _successPayment = useMemo(
    () => parcels.History?.filter((item) => item.payment === true),
    [parcels]
  );

  const isMobile = () => {
    return /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  };

  useEffect(() => {
    if (pdf) {
      const pdfBlob = base64ToPdf(pdf);
      if (pdfBlob) {
        const url = URL.createObjectURL(pdfBlob);
        if (isMobile()) {
          const link = document.createElement("a");
          link.href = url;
          link.download = "invoice.pdf";
          link.click();
        } else {
          window.open(url, "_blank");
        }
        URL.revokeObjectURL(url);
      } else {
        console.error("Failed to convert base64 string to PDF");
      }
    }
  }, [pdf]);

  return (
    <>
      {loading && <LoadingModal />}
      {modalInfo && (
        <RequestDeliveryModal
          title={modalInfo.title as string}
          clearModal={clearModal}
        />
      )}
      <ProfileLayout title="Мои посылки">
        <div className="title">Мои посылки</div>

        <PackageLink className="flex">
          <Block className="package-card">
            <a onClick={onShowModalRequest}>
              <PackageIcons />
              <div className="subtitle-block">Вызвать выездную группу</div>
            </a>
          </Block>

          <Block className="package-card">
            <Link to={"/tariff"}>
              <PricingIcons />
              <div className="subtitle-block">Тарифы</div>
            </Link>
          </Block>
        </PackageLink>

        <TabBarBlock>
          <PrimaryButtonStyled
            style={{
              backgroundColor: activeTab === "all" ? "#02447F" : "#8C8C8C",
            }}
            onClick={() => setActiveTab("all")}
          >
            Все
          </PrimaryButtonStyled>
          <PrimaryButtonStyled
            style={{
              backgroundColor: activeTab === "pay" ? "#02447F" : "#8C8C8C",
            }}
            onClick={() => setActiveTab("pay")}
          >
            Оплаченные
          </PrimaryButtonStyled>
          <PrimaryButtonStyled
            style={{
              backgroundColor: activeTab === "notpay" ? "#02447F" : "#8C8C8C",
            }}
            onClick={() => setActiveTab("notpay")}
          >
            Не оплаченные
          </PrimaryButtonStyled>
        </TabBarBlock>

        {activeTab === "all" && (
          <AllBlock parcels={parcels} setPdfInvoice={setPdfInvoice} />
        )}
        {activeTab === "pay" && (
          <PayBlock
            successPayment={_successPayment && _successPayment}
            setPdfInvoice={setPdfInvoice}
          />
        )}
        {activeTab === "notpay" && (
          <NotPayBlock
            notPayment={_notPayment && _notPayment}
            setPdfInvoice={setPdfInvoice}
          />
        )}
      </ProfileLayout>
    </>
  );
};

const PackageLink = styled.div`
  gap: 2rem;

  .package-card {
    max-width: 338px;

    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &:nth-child(1) {
      color: #8c8c8c;
    }

    &:nth-child(2) {
      background-color: #02447f;
      color: #fff;
    }

    svg {
      margin-bottom: 1.2rem;
    }

    @media (max-width: 1000px) {
      max-width: 50%;
    }

    @media (max-width: 480px) {
      max-width: 100%;
    }
  }

  margin-bottom: 5rem;

  @media (max-width: 480px) {
    display: block;

    .package-card {
      margin-bottom: 1.2rem;
    }

    margin-bottom: 2rem;
  }
`;

export default Package;
