import { Link } from "react-router-dom";
import MainLayouts from "../layouts/MainLayouts";

const PressCenter = () => {
  return (
    <MainLayouts title="Пресс-центр">
      <div className="title">Пресс-центр</div>

      <div className="presscenter">
        <div className="title-block">
          Контакты: <Link to={"tel:+996550559846"}>+996550559846</Link>
        </div>
        <div className="title-block">
          E-mail:{" "}
          <Link to={"mailto:alpha.cargo@gmail.com"}>
            <span>alphacargo3003@gmail.com</span>
          </Link>
        </div>
        <div className="title-block">Секретарь: Шамиева Аяна</div>
      </div>
    </MainLayouts>
  );
};

export default PressCenter;
