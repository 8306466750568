import { useState } from "react";
import AppModal from "./AppModal";
import { instance } from "../../api/instance.axios";
import { PrimaryButtonStyled } from "../atoms/UI/Buttons";
import { InputBorder } from "../atoms/UI/Inputs";
import styled from "@emotion/styled";

import { ToastContainer, toast } from "react-toastify";
import useValidAxiosError from "../../hooks/useValidAxiosError";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useAppDispatch } from "../../hooks/useAppDisptach";
import { actions } from "../../store/slice/profile.slice";

interface IProps {
  title: string;
  clearModal: () => void;
}


const RequestModal = ({ title, clearModal }: IProps) => {
  const [name, setName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");

  const { fetchError } = useValidAxiosError();
  const loader = useTypedSelector((state) => state.profile.loading);
  const dispatch = useAppDispatch();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (
      name.trim() === "" &&
      phone.trim() === "" 
    ) {
      toast.error("Все поля должны быть заполнены");
      return;
    }

    try {
      dispatch(actions.setLoading(true));
      const response = await instance.post("/request", {
        fullName: name,
        phoneNumber: phone      
    });

      if (response) {
        toast.success("Данные были успешно отправленый", {
          onClose: () => {
            clearModal();
          },
        });

        dispatch(actions.setLoading(false));

        window.open("https://wa.me/+996704703162", "_blank");
      }
    } catch (error: any) {
      fetchError(error);
      dispatch(actions.setLoading(false));
    }
  };
  return (
    <AppModal name={"call"} onClose={clearModal}>
      <div className="title-block">{title}</div>

      <form onSubmit={handleSubmit}>
        <InputBorderBottom
          type="text"
          placeholder="Представьтесь, пожалуйста"
          value={name}
          name="name"
          onChange={(e) => setName(e.target.value)}
          style={{ borderRadius: "10px", marginBottom: "1.6rem" }}
          bottom="1px solid #27457C4A"
        />

        <InputBorderBottom
          type="text"
          bottom="1px solid #27457C4A"
          name="phone"
          placeholder="+7 () ___ __ __"
          style={{ borderRadius: "10px", marginBottom: "1.6rem" }}
          onChange={(e) => setPhone(e.target.value)}
          value={phone}
          minLength={0}
          maxLength={20}
        />


        <PrimaryButtonStyled
          style={{ width: "100%" }}
          type="submit"
          disabled={loader ? true : false}
        >
          Оформить заявку
        </PrimaryButtonStyled>
      </form>

      <ToastContainer />
    </AppModal>
  );
};

const InputBorderBottom = styled(InputBorder)`
  border-radius: 0 !important;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;

  &:read-only {
    cursor: pointer;
    &::placeholder {
      color: #000;
    }
  }

  &:focus {
    outline: none;
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    transition: all 0.25s ease;
    box-shadow: inset 0 -2px 0 0 #02447f;
    border-color: transparent;
  }
`;

export default RequestModal;
