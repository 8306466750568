import styled from "@emotion/styled";
import { Block } from "../components/atoms/UI/Block";
import MainLayouts from "../layouts/MainLayouts";
import { useEffect, useMemo, useState } from "react";
import InputBoxForm from "../components/forms/InputBoxForm";
import { PrimaryButtonStyled } from "../components/atoms/UI/Buttons";
import { useAppDispatch } from "../hooks/useAppDisptach";
import { useTypedSelector } from "../hooks/useTypedSelector";
import { getTariffsInformationThunk } from "../store/thunk/assets.thunk";
import { ToastContainer, toast } from "react-toastify";

interface Inputs {
  country: string;
  city: string;
  type: string;
}

const Tariff = () => {
  const [inputs, setInputs] = useState<Inputs>({
    country: "",
    city: "",
    type: "",
  });

  const [activeDrop, setActiveDrop] = useState<boolean[]>([
    false,
    false,
    false,
  ]);

  const [show, setShow] = useState<boolean>(false);
  const [findTariff, setFindTariff] = useState<{
    time: string;
    price: number;
  }>();
  const dispatch = useAppDispatch();
  const tariffs = useTypedSelector((state) => state.assets.tariffs);
  const cityToName = useMemo(
    () => tariffs.map((item) => item.cityTo.cityname),
    [tariffs]
  );
  const countryName = useMemo(
    () => tariffs.map((item) => item.country.countryname),
    [tariffs]
  );
  const types = useMemo(() => tariffs.map((item) => item.type.name), [tariffs]);

  const toggleActiveDrop = (index: number) => {
    setActiveDrop((prev) => {
      const newState = [...prev];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const onSaveValueInput = (item: string, name: string, id: number) => {
    setInputs((prevInputs) => {
      let updatedInputs: Inputs = { ...prevInputs };

      if (name === "country") {
        updatedInputs.country = item;
        updatedInputs.city = "";
        updatedInputs.type = "";
      }

      if (name === "city") {
        updatedInputs.city = item;
        updatedInputs.type = "";
      }

      if (name === "type") {
        updatedInputs.type = item;
      }

      return updatedInputs;
    });

    setActiveDrop([false, false, false]);
  };

  const onMathTariff = () => {
    if (inputs.country && inputs.city && inputs.type) {
      const tofindTariff = tariffs.find(
        (item) =>
          item.country.countryname === inputs.country &&
          item.cityTo.cityname === inputs.city &&
          item.type.name === inputs.type
      );
      if (tofindTariff) {
        setFindTariff({
          time: tofindTariff.deliveryTime,
          price: tofindTariff.price,
        });
        setShow(true);
      }
    }
  };

  useEffect(() => {
    dispatch(getTariffsInformationThunk());
  }, [dispatch]);

  return (
    <>
      <MainLayouts title="Тарифы">
        <div className="title">Тарифы</div>

        <BlockStyled>
          <div className="title-block">
            Узнайте предварительную стоимость отправки
          </div>

          <InputBoxBlock className="flex gap-1_2">
            <InputBoxForm
              label="Страна"
              toggleActiveDrop={toggleActiveDrop}
              index={0}
              active={activeDrop[0]}
              value={inputs.country}
              name="country"
              onSaveValueInput={onSaveValueInput}
              arrayTariffs={countryName}
            />

            <InputBoxForm
              label="Город"
              toggleActiveDrop={toggleActiveDrop}
              index={1}
              active={activeDrop[1]}
              value={inputs.city}
              name="city"
              onSaveValueInput={onSaveValueInput}
              arrayTariffs={cityToName}
            />

            <InputBoxForm
              label="Тип"
              toggleActiveDrop={toggleActiveDrop}
              index={2}
              active={activeDrop[2]}
              value={
                inputs.type.split(" ").length >= 2
                  ? `${inputs.type.split(" ")[0]}...`
                  : inputs.type
              }
              name="type"
              onSaveValueInput={onSaveValueInput}
              arrayTariffs={types}
            />
          </InputBoxBlock>

          {show && (
            <TariffMath className="flex ">
              <div className="subtitle-block">
                Срок доставки: {findTariff?.time && findTariff?.time} дней
              </div>
              <div className="subtitle-block">
                Стоимость за кг: {findTariff?.price && findTariff?.price}₽
              </div>
            </TariffMath>
          )}

          <PrimaryButtonStyled onClick={onMathTariff}>
            Рассчитать
          </PrimaryButtonStyled>
        </BlockStyled>
      </MainLayouts>

      <ToastContainer />
    </>
  );
};

const BlockStyled = styled(Block)`
  width: 100%;
  max-width: 750px;

  input,
  button {
    width: 100%;
  }

  button {
    margin-top: 3rem;

    @media (max-width: 480px) {
      margin-top: 2rem;
    }
  }
`;

const InputBoxBlock = styled.div`
  .input_arrow {
    position: relative;
    z-index: 1;
    svg {
      position: absolute;

      right: 20px;

      top: 50%;
      transform: translateY(-50%);

      transition: transform 0.2s linear;
    }

    &.active {
      svg {
        transform: rotate(180deg);
        top: 40%;
      }
    }
  }

  @media (max-width: 480px) {
    display: block;
  }
`;

const TariffMath = styled.div`
  .subtitle-block {
    font-weight: 600;
  }

  gap: 2rem;

  margin-top: 3rem;

  @media (max-width: 480px) {
    margin-top: 2rem;

    display: block;

    .subtitle-block {
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

export default Tariff;
