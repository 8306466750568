import styled from "@emotion/styled";
import { Link, useNavigate } from "react-router-dom";
import { PrimaryButtonStyled } from "../atoms/UI/Buttons";
import { localStorageWrapper } from "../../config/storage";
import { useAppDispatch } from "../../hooks/useAppDisptach";
import { getUserInformationThunk } from "../../store/thunk/system.thunk";

const Popover = ({
  onShowModalRequestChange,
}: {
  onShowModalRequest: () => void;
  onShowModalRequestChange: () => void;
}) => {
  return (
    <PopoverBlock className="popover_block popover_block--tariff">
      <div className="card">
        <Link className="card_link" to={"/calculater"}>
          <div className="subtitle-block subtitle-block--popover">
            Калькулятор
          </div>
          <div className="subtitle-block-ld">
            Рассчитайте стоимость отправки своего груза
          </div>
        </Link>
        <Link className="card_link" to={"/tariff"}>
          <div className="subtitle-block subtitle-block--popover">Тарифы</div>
          <div className="subtitle-block-ld">
            Узнайте цены на посылки в разные города
          </div>
        </Link>
        <Link className="card_link" to={"/tracking"}>
          <div className="subtitle-block subtitle-block--popover">
            Отслеживания
          </div>
          <div className="subtitle-block-ld">
            Отслеживайте свои посылки по номеру накладной
          </div>
        </Link>
        <Link className="card_link" to={"/iin"}>
          <div className="subtitle-block subtitle-block--popover">
            Официальный груз
          </div>
          <div className="subtitle-block-ld">
            Отслеживайте свои посылки по номеру накладной
          </div>
        </Link>
        <a className="card_link" onClick={onShowModalRequestChange}>
          <div className="subtitle-block subtitle-block--popover">
            Заказать выездную группу
          </div>
          <div className="subtitle-block-ld">Вызовите выездную группу</div>
        </a>
      </div>
    </PopoverBlock>
  );
};

export const PopoverClient = ({
  onShowModalRequest,
}: {
  onShowModalRequest: () => void;
}) => {
  return (
    <PopoverBlock className="popover_block">
      <div className="card">
        <Link className="card_link" to={"/ltl"}>
          <div className="subtitle-block subtitle-block--popover">LTL</div>
          <div className="subtitle-block-ld">
            Перевозка сборных грузов для вашего бизнеса
          </div>
        </Link>
        <Link className="card_link" to={"/media"}>
          <div className="subtitle-block subtitle-block--popover">
            Альфа медиа
          </div>
          <div className="subtitle-block-ld">
            Размещайте свою рекламу на ТВ на складах Альфа Карго
          </div>
        </Link>
        <Link className="card_link" to={"/bis/tariff"}>
          <div className="subtitle-block subtitle-block--popover">Тарифы</div>
          <div className="subtitle-block-ld">
            Подробная информация о тарифах для бизнеса
          </div>
        </Link>
        <a className="card_link" onClick={onShowModalRequest}>
          <div className="subtitle-block subtitle-block--popover">
            Заключить договор
          </div>
          <div className="subtitle-block-ld">
            Отправляйте товары и другие грузы по всему миру
          </div>
        </a>
        <Link className="card_link" to={"/franchise"}>
          <div className="subtitle-block subtitle-block--popover">Франшиза</div>
          <div className="subtitle-block-ld">
            Готовые логистические маршруты, бесплатное обучение и продвижение,
            миллиона лояльных клиентов
          </div>
        </Link>
      </div>
    </PopoverBlock>
  );
};

export const PopoverService = ({
  onShowModalRequest,
}: {
  onShowModalRequest: (link: string) => void;
}) => {
  return (
    <PopoverBlock className="popover_block popover_block--services">
      <div className="card">
        <a
          className="card_link"
          onClick={() => onShowModalRequest("/profile/package")}
        >
          <div className="subtitle-block subtitle-block--popover">
            Онлайн накладная
          </div>
          <div className="subtitle-block-ld">Следите за документами онлайн</div>
        </a>
        <Link className="card_link" to={"/"}>
          <div className="subtitle-block subtitle-block--popover">
            Мобильное приложение
          </div>
          <div className="subtitle-block-ld">
            Вся информация у вас под рукой
          </div>
        </Link>
        <a
          className="card_link"
          onClick={() => onShowModalRequest("/profile/main")}
        >
          <div className="subtitle-block subtitle-block--popover">
            Личный кабинет
          </div>
          <div className="subtitle-block-ld">
            Зарегистрируйтесь и будте в курсе своих отправок
          </div>
        </a>
        <Link className="card_link" to={"/cargo"}>
          <div className="subtitle-block subtitle-block--popover">
            Программа лояльности
          </div>
          <div className="subtitle-block-ld">
            Получайте скидки и копите бонусы
          </div>
        </Link>

        <Link className="card_link" to={"/services"}>
          <div className="subtitle-block subtitle-block--popover">Услуги</div>
          <div className="subtitle-block-ld">Все наши услуги</div>
        </Link>

        <Link className="card_link" to={"/payment"}>
          <div className="subtitle-block subtitle-block--popover">Онлайн-оплата</div>
          <div className="subtitle-block-ld">Онлайн-оплата услуг картой</div>
        </Link>
      </div>
    </PopoverBlock>
  );
};

export const PopoverProfile = ({ showPopover }: { showPopover: boolean }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onLogout = async () => {
    localStorageWrapper.clear();
    navigate("/");
    dispatch(getUserInformationThunk());
    window.location.reload();
  };

  return (
    <PopoverBlock
      style={{ display: `${showPopover ? "block" : "none"}`, right: "0%" }}
      profile
    >
      <Link to={"/profile/main"} className="subtitle-block">
        Личный кабинет
      </Link>
      <div>
        <PrimaryButtonStyled
          style={{ width: "100%", marginTop: "1.6rem" }}
          onClick={onLogout}
        >
          Выйти
        </PrimaryButtonStyled>
      </div>
    </PopoverBlock>
  );
};

const PopoverBlock = styled.div<{ profile?: boolean }>`
  background-color: #ffffff;

  border-radius: 10px;
  padding: 4rem;

  width: 100%;
  min-width: 570px;

  position: absolute;
  top: 100%;
  z-index: 50;

  .subtitle-block {
    transition: color 0.2s linear;
    &:hover {
      color: #94c325;
    }
  }

  .subtitle-block-ld {
    font-size: 1.5rem;
    color: #8c8c8c;
    margin-top: 1.2rem;
  }

  .card {
    display: flex;
    flex-wrap: wrap;

    gap: 2rem;

    a {
      width: 45%;
    }
  }

  display: none;

  ${(props) =>
    props.profile &&
    `
    min-width: 300px!important;
  `}

  @media (max-width: 1000px) {
    left: -205%;
  }

  @media (max-width: 768px) {
    &.popover_block--tariff {
      left: -100% !important;
    }
  }

  @media (max-width: 480px) {
    left: -135%;
    width: 100%;
    padding: 2rem;
    min-width: 400px;
    max-width: 100%;
    z-index: 90;

    &.popover_block--services {
      left: -15%!important;
    }

    .subtitle-block-ld {
      font-weight: 400;
      font-size: 1.2rem;
      margin-top: 1rem;
    }

    &.popover_block--tariff {
      left: -15% !important;
    }
  }
`;

export default Popover;
