import styled from "@emotion/styled";
import { Block } from "../../../components/atoms/UI/Block";
import ProfileLayout from "../../../layouts/ProfileLayout";
import { useAppDispatch } from "../../../hooks/useAppDisptach";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { useEffect } from "react";
import { getNotificaitonsInformationThunk } from "../../../store/thunk/profile.thunk";
import { formatDate } from "../../../utlis/formatNumberLocal";
import { actions } from "../../../store/slice/profile.slice";
import LoadingModal from "../../../components/modal/LoadingModal";
import { getTokenInLocalStorage } from "../../../utlis/getTokenLocal";
import { instance } from "../../../api/instance.axios";
import { PrimaryButtonStyled } from "../../../components/atoms/UI/Buttons";

const Notification = () => {
  const disptach = useAppDispatch();
  const notifications = useTypedSelector(
    (state) => state.profile.notifications
  );
  const loading = useTypedSelector((state) => state.profile.loading);

  useEffect(() => {
    disptach(actions.setLoading(true));
    disptach(getNotificaitonsInformationThunk()).finally(() => {
      disptach(actions.setLoading(false));
    });
  }, [disptach]);

  const patchNotificationRead = async (id: string) => {
    disptach(actions.setLoading(true));
    await instance
      .patch(
        `/notifications/${id}`,
        {},
        {
          headers: {
            Authorization: "Bearer " + getTokenInLocalStorage(),
          },
        }
      )
      .then(() => disptach(getNotificaitonsInformationThunk()))
      .catch(() => disptach(actions.setLoading(false)))
      .finally(() => disptach(actions.setLoading(false)));
  };

  return (
    <>
      {loading && <LoadingModal />}
      <ProfileLayout title="Уведомление">
        <div className="title">Уведомления</div>
        {notifications &&
          [...notifications].reverse().map((item) => (
            <NotificationBlock key={item.id}>
              {!item.read && (
                <div style={{ textAlign: "right" }}>
                  <PrimaryButtonStyled
                    style={{ opacity: "0.6" }}
                    onClick={() => patchNotificationRead(item.id)}
                  >
                    Читать
                  </PrimaryButtonStyled>
                </div>
              )}
              <div className="title-block">{item.title}</div>
              <div className="subtitle-block">
                Дата: {formatDate(item.createdAt)}
              </div>
              <div className="subtitle-block">
                Статус: {item.read ? "Прочитано" : "Не прочитано"}
              </div>
            </NotificationBlock>
          ))}

        {!notifications.length && (
          <div className="title-block" style={{ textAlign: "center" }}>
            Пусто
          </div>
        )}
      </ProfileLayout>
    </>
  );
};

const NotificationBlock = styled(Block)`
  &:hover {
    background-color: #02447f;
    color: #fff;

    button {
      background-color: #fff;
      color: #02447f;
    }
  }

  transition: background-color 0.2s linear, color 0.2s linear;

  margin-bottom: 3rem;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (max-width: 1000px) {
    margin-bottom: 1.6rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  @media (max-width: 480px) {
    margin-bottom: 1.2rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

export default Notification;
