import { IBags, ICities, ICountries, IOffices, IParcelTypes, IParcelsInformation, IParcelsInvoiceInformation, IServices, ITariffsInformation, IUserInfo } from "../types/assets.types";
import { getTokenInLocalStorage } from "../utlis/getTokenLocal";
import { instance } from "./instance.axios";

export const assetsApi = {
    async getUserInformation(): Promise<IUserInfo> {
        return instance.get(`/users`, {
            headers: {
                Authorization: `Bearer ${getTokenInLocalStorage()}`
            }
        })
    },

    async getServicesInformation(): Promise<IServices[]> {
        return instance.get(`/services`)
    },

    async getOffiesInformation(id?: string): Promise<IOffices[]> {
        return instance.get(`/offices${id ? `/${id}` : ''}`)
    },

    async getLocationsCitiesInformation(): Promise<ICities[]> {
        return instance.get(`/locations/cities`)
    },

    async getLocationsCitiesInformationById(id: number): Promise<ICities> {
        return instance.get(`/locations/cities/${id}`)
    },


    async getLocationsCountiresInformation(): Promise<ICountries[]> {
        return instance.get(`/locations/countries`)
    },

    async getBagsInformation(): Promise<IBags[]> {
        return instance.get(`/bags`)
    },

    async getParcelTypesInformation(): Promise<IParcelTypes[]> {
        return instance.get(`/parcel-type`)
    },

    async getParcelsInformation(): Promise<IParcelsInformation> {
        return instance.get(`/parcels`, {
            headers: {
                Authorization: `Bearer ${getTokenInLocalStorage()}`
            }
        })
    },

    async getParcelsGetInvoiseInformation(invoiceNumber: string): Promise<IParcelsInvoiceInformation> {
        return instance.get(`/parcels/invoice/${invoiceNumber}`, {
            headers: {
                Authorization: `Bearer ${getTokenInLocalStorage()}`
            }
        })
    },

    async getParcelsGetInvoisePDFInformation(invoiceNumber: string): Promise<string> {
        return instance.get(`/parcels/invoice/${invoiceNumber}/pdf`, {
            headers: {
                Authorization: `Bearer ${getTokenInLocalStorage()}`
            }
        })
    },

    async getNotificationsInformation(): Promise<any[]> {
        return instance.get(`/notifications`, {
            headers: {
                Authorization: `Bearer ${getTokenInLocalStorage()}`
            }
        })
    },
    
    async getTariffsInformation(): Promise<ITariffsInformation[]> {
        return instance.get(`/tariffs`)
    },
}