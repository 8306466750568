import styled from "@emotion/styled";

const GuideTeamSpec = () => {
  return (
    <div>
      <GuideTeamBlock className="flex">
        <GuideTeamCard>
          <div className="guide-card_img">
            <div></div>
          </div>

          <div className="guide-card_content">
            <div className="title-block">Cпецификатор</div>
            <div className="subtitle-block">Канатбек уулу Азизбек</div>
          </div>
        </GuideTeamCard>

        <GuideTeamCard>
          <div className="guide-card_img">
            <div></div>
          </div>

          <div className="guide-card_content">
            <div className="title-block">Cпецификатор</div>
            <div className="subtitle-block">Айталы уулу Марат</div>
          </div>
        </GuideTeamCard>

        <GuideTeamCard>
          <div className="guide-card_img">
            <div></div>
          </div>

          <div className="guide-card_content">
            <div className="title-block">Cпецификатор</div>
            <div className="subtitle-block">Медетбек уулу Жээнчоро</div>
          </div>
        </GuideTeamCard>

        <GuideTeamCard>
          <div className="guide-card_img">
            <div></div>
          </div>

          <div className="guide-card_content">
            <div className="title-block">Cпецификатор</div>
            <div className="subtitle-block">Джаманкулов Эламан</div>
          </div>
        </GuideTeamCard>

        <GuideTeamCard>
          <div className="guide-card_img">
            <div></div>
          </div>

          <div className="guide-card_content">
            <div className="title-block">Cпецификатор</div>
            <div className="subtitle-block">Медетбек Назар</div>
          </div>
        </GuideTeamCard>

        <GuideTeamCard>
          <div className="guide-card_img">
            <div></div>
          </div>

          <div className="guide-card_content">
            <div className="title-block">Cпецификатор</div>
            <div className="subtitle-block">Самаганов Нурбек</div>
          </div>
        </GuideTeamCard>

        <GuideTeamCard>
          <div className="guide-card_img">
            <div></div>
          </div>

          <div className="guide-card_content">
            <div className="title-block">Cпецификатор</div>
            <div className="subtitle-block">Токтогулов Азамат</div>
          </div>
        </GuideTeamCard>

        <GuideTeamCard>
          <div className="guide-card_img">
            <div></div>
          </div>

          <div className="guide-card_content">
            <div className="title-block">Cпецификатор</div>
            <div className="subtitle-block">Шаймерден уулу Омурбек</div>
          </div>
        </GuideTeamCard>

        <GuideTeamCard>
          <div className="guide-card_img">
            <div></div>
          </div>

          <div className="guide-card_content">
            <div className="title-block">Cпецификатор</div>
            <div className="subtitle-block">Исмаев Элдияр</div>
          </div>
        </GuideTeamCard>

        <GuideTeamCard>
          <div className="guide-card_img">
            <div></div>
          </div>

          <div className="guide-card_content">
            <div className="title-block">контроллер</div>
            <div className="subtitle-block">Усенбаев Бекболот</div>
          </div>
        </GuideTeamCard>
      </GuideTeamBlock>
    </div>
  );
};

const GuideTeamBlock = styled.div`
  margin-top: 5rem;
  flex-wrap: wrap;
`;
const GuideTeamCard = styled.div`
  width: 25%;
  .guide-card_img {
    div {
      width: 100px;
      height: 100px;

      background-color: #ccc;

      border-radius: 50%;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &--big {
      div {
        width: 160px;
        height: 160px;
      }
    }
  }

  display: flex;
  flex-direction: column;
  align-items: center;

  .guide-card_content {
    text-align: center;

    margin-top: 3rem;
  }

  margin-bottom: 5rem;

  @media (max-width: 768px) {
    margin-bottom: 2rem;
  }

  @media (max-width: 480px) {
    width: 50%;
  }
`;

export default GuideTeamSpec;
