import { Link } from "../../atoms/UI/Link";

const RulesAcceptanceBlock = () => {
  return (
    <div className="rules_link-1">
      <Link link="#" bottom="1.6rem">
        Договор-оферта (для физических и юридических лиц, не являющихся
        Интернет-магазинами)
      </Link>
      <Link link="#" bottom="1.6rem">
        Договор-оферта (для Интернет-магазинов)
      </Link>
      <Link link="#" bottom="1.6rem">
        Договор-оферта (для самозанятых граждан)
      </Link>
      <Link link="#" bottom="1.6rem">
        Приложения к Договору-оферте
      </Link>
      <Link link="#" bottom="1.6rem">
        Регламент возмездного оказания курьерских услуг
      </Link>
      <Link link="#" bottom="1.6rem">
        Приложение №1 к Регламенту - Услуги и условия их оказания
      </Link>
      <Link link="#" bottom="1.6rem">
        Приложение №2 к Регламенту - Отправления, запрещенные к передаче
        курьерской службе для доставки и/или хранения
      </Link>
      <Link link="#" bottom="1.6rem">
        Приложение №3 к Регламенту - Требования к упаковке и маркировке
        Отправлений
      </Link>
      <Link link="#" bottom="1.6rem">
        Приложение №4 к Регламенту - Формы накладных Исполнителя
      </Link>
      <Link link="#" bottom="1.6rem">
        Приложение №5 к Регламенту - Условия и правила предоставления и
        использования Сервиса «Наложка»
      </Link>
      <Link link="#" bottom="1.6rem">
        Образец доверенности (можно использовать иную форму)
      </Link>
    </div>
  );
};

export default RulesAcceptanceBlock;
