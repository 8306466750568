import { useNavigate } from "react-router-dom";
import LoginModal from "../components/modal/LoginModal";
import HeaderLinks from "../components/organisms/Headers/HeaderLinks";
import HeaderPages from "../components/organisms/Headers/HeaderPages";
import useManagerModal from "../hooks/useManagerModal";
import { getTokenInLocalStorage } from "../utlis/getTokenLocal";
import { useEffect, useState } from "react";
import { useTypedSelector } from "../hooks/useTypedSelector";
import { useAppDispatch } from "../hooks/useAppDisptach";
import { getUserInformationThunk } from "../store/thunk/system.thunk";

const Layouts = () => {
  const { modalInfo, clearModal, setLoginModal } = useManagerModal();
  const [active, setActive] = useState<boolean>(false);
  const [showPopover, setShowPopover] = useState<boolean>(false)
  const navigate = useNavigate();
  const [getlocation, setGetLocation] = useState<string>("")
  const user = useTypedSelector((state) => state.system.user);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (getTokenInLocalStorage()) {
      dispatch(getUserInformationThunk());
    }
  }, [dispatch]);

  const handleOpenModal = () => {
    if (getTokenInLocalStorage() === null) {
      setLoginModal("Войти");
    } else {
      setShowPopover(!showPopover)
    }
  };
  return (
    <>
      {modalInfo && (
        <LoginModal title={modalInfo.title as string} clearModal={clearModal} />
      )}
      <div className="page_headers">
        <HeaderPages
          handleOpenModal={handleOpenModal}
          setActive={setActive}
          active={active}
          phone={user!.phone as string}
          getlocation={getlocation}
          setGetLocation={setGetLocation}
          showPopover={showPopover}
        />
        <HeaderLinks
          handleOpenModal={handleOpenModal}
          setActive={setActive}
          active={active}
          phone={user!.phone as string}
          getlocation={getlocation}
          setGetLocation={setGetLocation}
          showPopover={showPopover}
        />
      </div>
    </>
  );
};

export default Layouts;
