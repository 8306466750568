import { RefObject } from "react";
import { Block } from "../../atoms/UI/Block";
import ContractForm from "../../forms/ContractForm";

const Contract = ({ scrollRef }: { scrollRef: RefObject<any> }) => {
  return (
    <div className="ltl_cargo" ref={scrollRef}>
      <div className="title">Заключить договор</div>

      <Block>
        <ContractForm />
      </Block>
    </div>
  );
};

export default Contract;
