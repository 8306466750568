import styled from "@emotion/styled";
import MapMarker from "../MapMarker";

const LTLMap = () => {
  return (
    <LTLMapBlock>
      <MapMarker/>
    </LTLMapBlock>
  );
};

const LTLMapBlock = styled.div`
  height: 700px;
`

export default LTLMap;
