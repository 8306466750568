export function formatNumberLocal(number: number) {
    return number.toLocaleString('ru-RU', { maximumFractionDigits: 0 })
}

export function formatDate(isoDate: string) {
    const date = new Date(isoDate);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    const formattedDate = `${day}.${month}.${year}`;

    return formattedDate
}