import styled from "@emotion/styled";

const InvestMixing = () => {
  return (
    <InvestMixingBlock>
      <div className="title">Общие сведения</div>

      <div className="invest_mixing flex gap-3">
        <div className="invest_mixing-block">
          <div className="invest_mixing-content">
            <div className="title-block">Наименование организации</div>
            <div className="subtitle-block">
              Общество с ограниченной ответственностью «Альфа карго плюс»
            </div>
          </div>

          <div className="invest_mixing-content">
            <div className="title-block">Сокращенное наименовение</div>
            <div className="subtitle-block">ОсОО «Альфа карго плюс»</div>
          </div>

          <div className="invest_mixing-content">
            <div className="title-block">Место нахождения</div>
            <div className="subtitle-block">г. Бишкек, ул. Анкара 58</div>
          </div>

          <div className="invest_mixing-content">
            <div className="title-block">Юридический адрес</div>
            <div className="subtitle-block">г. Бишкек, ул. Анкара 58.</div>
          </div>
        </div>

        <div className="invest_mixing-block">
          <div className="invest_mixing-content">
            <div className="title-block">Зарегистрировавший орган</div>
            <div className="subtitle-block">УГНС по Октябрьскому району</div>
          </div>

          <div className="invest_mixing-content">
            <div className="title-block">Номер государственной регистрации</div>
            <div className="subtitle-block">№ СФ 104100049554</div>
          </div>

          <div className="invest_mixing-content">
            <div className="title-block">Дата государственной регистрации</div>
            <div className="subtitle-block">18.05.2015</div>
          </div>

          <div className="invest_mixing-content">
            <div className="title-block">ФИО руководителя</div>
            <div className="subtitle-block">
              Токтоболотов Исламбек Кемелбекович
            </div>
          </div>
        </div>
      </div>
    </InvestMixingBlock>
  );
};

const InvestMixingBlock = styled.div`
  margin-top: 8rem;

  .invest_mixing-content {
    margin-bottom: 3rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media (max-width: 1000px) {
    margin-top: 5rem;
  }

  @media (max-width: 768px) {
    margin-top: 3rem;

    .title {
      margin-bottom: 2rem;
    }

    .invest_mixing {
      display: flex;
      justify-content: space-between;

      .title-block {
        font-size: 2rem;
      }

      .subtitle-block {
        font-size: 1.6rem;
      }
    }
  }

  @media (max-width: 480px) {
    .invest_mixing {
      display: inline-block;

      .title-block,
      .subtitle-block {
        font-size: 1.3rem;
        font-weight: 400;
      }

      .title-block {
        font-weight: 600;
      }

      .invest_mixing-content {
        margin-bottom: 1.2rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`;

export default InvestMixing;
