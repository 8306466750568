import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from '../system/profile.system';
import { IParcelsInformation, IParcelsInvoiceInformation } from '../../types/assets.types';
import { getNotificaitonsInformationThunk, getParcelInoviceInformationThunk, getParcelInovicePDFInformationThunk, getParcelsInformationThunk } from '../thunk/profile.thunk';

const profileSlice = createSlice({
    name: 'profileSlice',
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload
        }
    },
    extraReducers(builder) {
        builder.addCase(
            getParcelsInformationThunk.fulfilled,
            (state, action: PayloadAction<IParcelsInformation>) => {
                if (action.payload) {
                    return {
                        ...state,
                        percels: action.payload
                    }
                }
            }
        ).addCase(
            getNotificaitonsInformationThunk.fulfilled,
            (state, action: PayloadAction<any[]>) => {
                if (action.payload) {
                    return {
                        ...state,
                        notifications: action.payload
                    }
                }
            }
        ).addCase(
            getParcelInoviceInformationThunk.fulfilled,
            (state, action: PayloadAction<IParcelsInvoiceInformation>) => {
                if (action.payload) {
                    return {
                        ...state,
                        invoice: action.payload
                    }
                }
            }
        ).addCase(
            getParcelInovicePDFInformationThunk.fulfilled,
            (state, action: PayloadAction<string>) => {
                if (action.payload) {
                    return {
                        ...state,
                        pdf: action.payload
                    }
                }
            }
        )
    }
});

export const { actions } = profileSlice;

export default profileSlice.reducer;
