import styled from "@emotion/styled";
import {
  PrimaryButtonStyled,
  ToggleButtonTab,
} from "../components/atoms/UI/Buttons";
import MainLayouts from "../layouts/MainLayouts";
import { useEffect, useState } from "react";
import { TabBarBlock } from "../components/atoms/UI/Block";
import { Input } from "../components/atoms/UI/Inputs";
import useScrollHorizontal from "../hooks/useScriollHorizontal";
import PrivateResultBlock from "../components/molecules/Payment/PrivateResultBlock";
import JuridicalResultBlock from "../components/molecules/Payment/JuridicalResultBlock";
import { formatNumberLocal } from "../utlis/formatNumberLocal";
import { useAppDispatch } from "../hooks/useAppDisptach";
import {
  getParcelInoviceInformationThunk,
  getParcelsInformationThunk,
} from "../store/thunk/profile.thunk";
import { ToastContainer, toast } from "react-toastify";
import useDebounced from "../hooks/useDebounced";
import { useTypedSelector } from "../hooks/useTypedSelector";
import { DraggerStyled } from "../components/forms/СareerForm";
import { PDFIcons } from "../components/atoms/Icons";
import { instance } from "../api/instance.axios";
import { onKeyPress } from "../utlis/keypress";
import { FaWhatsapp } from "react-icons/fa";

const Payment = () => {
  const [toggleDelivery, setToggleDelivery] = useState<"delivery" | "product">(
    "delivery"
  );
  const [toggleFace, setToggleFace] = useState<"private" | "juridical">(
    "private"
  );
  const [activeTab, setActiveTab] = useState<"package" | "card" | "aggrement">(
    "package"
  );
  const [show, setShow] = useState<boolean>(false);

  const [numberValue, setNumberValue] = useState<string>("");
  const numberValueDebounced = useDebounced(numberValue, 500);
  const dispatch = useAppDispatch();
  const parcel = useTypedSelector((state) => state.profile.invoice.Statuses);
  const { containerRef, handleTouchEnd, handleTouchMove, handleTouchStart } =
    useScrollHorizontal();
  const [invoice, setInvoice] = useState<string>();

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState<string>("");
  const [tel, setTel] = useState<string>("");
  const [sum, setSum] = useState<string>("");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (file) {
      setSelectedFile(file);
      setFileName(file.name);
    }
  };

  const handleSubmitChek = async () => {
    try {
      if (tel && sum && selectedFile) {
        const formData = new FormData();
        formData.append("phoneNumber", tel);
        formData.append("amount", sum);
        formData.append("checkFile", selectedFile);

        const res = await instance.post("payment", formData);

        if (res) {
          toast.success("Все успешно");
        }
      }
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  };

  const handleSearch = async () => {
    dispatch(getParcelInoviceInformationThunk(numberValue)).then((res) => {
      if (typeof (res as any).payload.Status === "string") {
        if ((res as any).payload.Status.includes("Bad, not found")) {
          toast.error("Номер не правильно");
          setShow(false);
        }
      }
    });
  };

  useEffect(() => {
    if (numberValueDebounced.length <= 0) {
      setShow(false);
    }
  }, [numberValueDebounced]);

  useEffect(() => {
    dispatch(getParcelsInformationThunk());
  }, [dispatch]);

  useEffect(() => {
    if (parcel) {
      if (Array.isArray(parcel)) {
        const findNotPaymeny = parcel
          .filter((parcels) => parcels.payment === false)
          .at(-1);

        if (findNotPaymeny) {
          setInvoice(findNotPaymeny.sum);
          setShow(true);
        } else {
          toast.success("Все оплачено");
        }
      }
    }
  }, [parcel]);

  return (
    <>
      <MainLayouts title="Оплата">
        <div className="title">Онлайн-оплата услуг</div>

        <PaymentBlock
          style={{
            flexDirection: "column",
          }}
          className="flex gap-3"
        >
          <PaymentStyled>
            <div className="payment_toggle">
              <div className="title-block">Выбор оплаты</div>
              <ToggleButtonTab>
                <button
                  className={`toggle_button ${
                    toggleDelivery === "delivery" ? "toggle_button--active" : ""
                  }`}
                  onClick={() => setToggleDelivery("delivery")}
                >
                  За доставку
                </button>
              </ToggleButtonTab>
            </div>

            <div className="payment_toggle">
              <div className="title-block">Плательщик</div>
              <ToggleButtonTab>
                <button
                  className={`toggle_button ${
                    toggleFace === "private" ? "toggle_button--active" : ""
                  }`}
                  onClick={() => setToggleFace("private")}
                >
                  Частное лицо
                </button>
                <button
                  className={`toggle_button ${
                    toggleFace === "juridical" ? "toggle_button--active" : ""
                  }`}
                  onClick={() => setToggleFace("juridical")}
                >
                  Юридическое лицо
                </button>
              </ToggleButtonTab>
            </div>

            <div className="payment_toggle">
              <div className="title-block">Основание платежа</div>
              {toggleDelivery === "delivery" && (
                <TabBarContainer
                  ref={containerRef}
                  onTouchStart={handleTouchStart}
                  onTouchMove={handleTouchMove}
                  onTouchEnd={handleTouchEnd}
                >
                  <TabBarBlock payment>
                    <PrimaryButtonStyled
                      style={{
                        backgroundColor:
                          activeTab === "package" ? "#02447F" : "#8C8C8C",
                      }}
                      onClick={() => setActiveTab("package")}
                    >
                      Номер накладной
                    </PrimaryButtonStyled>
                  </TabBarBlock>
                </TabBarContainer>
              )}

              <div className="payment_toggle-input flex gap-1_2">
                <Input
                  placeholder="Введите номер"
                  value={numberValue}
                  onChange={(e) => setNumberValue(e.target.value)}
                  style={{ backgroundColor: "#FFF" }}
                />
                <PrimaryButtonStyled onClick={handleSearch}>
                  Найти
                </PrimaryButtonStyled>
              </div>

              {show && (
                <div className="title-block title-block--payment">
                  Суммма: {formatNumberLocal(Number(invoice))}₽
                </div>
              )}
            </div>

            {/* {toggleFace === "private" && show && <PrivateResultBlock />} */}
            {/* {toggleFace === "juridical" && show && <JuridicalResultBlock />} */}
          </PaymentStyled>

          {show && (
            // <PayForm>
            //   <div className="pay_input-form">
            //     <div className="title-block">Номер телефона</div>
            //     <Input
            //       style={{ backgroundColor: "white", width: "100%" }}
            //       placeholder="Телефон номер"
            //       value={tel}
            //       onChange={(e) => setTel(e.target.value)}
            //     />
            //   </div>

            //   <div className="pay_input-form">
            //     <div className="title-block">Сумма платежа</div>
            //     <Input
            //       style={{ backgroundColor: "white", width: "100%" }}
            //       placeholder="Сумма платежа"
            //       value={sum}
            //       onChange={(e) => setSum(e.target.value)}
            //       onKeyPress={onKeyPress}
            //     />
            //   </div>

            //   <DraggerStyled className="kb-file-upload career-block">
            //     <div className="title-block">Чек</div>
            //     <div className="file-upload-box">
            //       <input
            //         type="file"
            //         id="fileupload"
            //         className="file-upload-input"
            //         accept=".jpeg, .png"
            //         onChange={handleInputChange}
            //       />
            //       <span className="subtitle-block">
            //         Выберите файл или перетащите в эту область
            //       </span>
            //     </div>

            //     {fileName && (
            //       <div
            //         className="flex items-center gap-1_2 file_name"
            //         style={{ marginTop: "2rem" }}
            //       >
            //         <div>
            //           <PDFIcons />
            //         </div>
            //         <div>{fileName}</div>
            //       </div>
            //     )}
            //   </DraggerStyled>

            <PrimaryButtonStyled
              style={{
                width: "fit-content",
                display: "flex",
                alignItems: "center",
                gap: "100px",
              }}
              onClick={() =>
                window.open("https://wa.me/996995121822", "_blank")
              }
            >
              Оплатить
              <FaWhatsapp size={32} color="green" />
            </PrimaryButtonStyled>
            // </PayForm>
          )}
        </PaymentBlock>
      </MainLayouts>

      <ToastContainer />
    </>
  );
};

const PaymentStyled = styled.div`
  width: 100%;
  max-width: 600px;

  .payment_toggle {
    margin-bottom: 3rem;

    &-input {
      margin-top: 3rem;
    }
  }

  .title-block {
    &--payment {
        color: rgb(2, 68, 127);
        font-size: 1.5rem;
        text-align: right;
        margin-top: 3rem;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    max-width: 100%;

    .payment_toggle
        &-input {
          display: block!important;
        }
      }
  }
`;

const TabBarContainer = styled.div`
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const PaymentBlock = styled.div`
  .payment_instraction {
    width: 100%;
    height: 700px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  @media (max-width: 768px) {
    display: block !important;
  }
`;

const PayForm = styled.div`
  width: 40%;

  .pay_input-form {
    margin-bottom: 1.4rem;
  }

  .career-block {
    margin-bottom: 1.4rem;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export default Payment;
