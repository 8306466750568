import styled from "@emotion/styled";
import { PrimaryButtonStyled } from "../atoms/UI/Buttons";
import { Input } from "../atoms/UI/Inputs";
import { useState, FormEvent } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { instance } from "../../api/instance.axios";
import useValidAxiosError from "../../hooks/useValidAxiosError";
import { onKeyPress } from "../../utlis/keypress";

interface FormState {
  too: string;
  nameCompany: string;
  iin: string;
  email: string;
  phoneNumber: string;
}

const ContractForm = () => {
  const [contractValue, setContractValue] = useState<
    Pick<FormState, "too" | "email" | "iin" | "nameCompany">
  >({
    too: "",
    nameCompany: "",
    iin: "",
    email: "",
  });

  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const { fetchError } = useValidAxiosError();

  const onSubmitForm = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const data: FormState = {
      ...contractValue,
      phoneNumber,
    };

    const emptyFields = Object.entries(data)
      .filter(([, value]) => !value)
      .map(([key]) => key);

    if (emptyFields.length > 0) {
      toast.error(`Заполните следующие поля: ${emptyFields.join(", ")}`);
    } else {
      try {
        const response = await instance.post("/contract", {
          organizationalStructure: data.too,
          organizationName: data.nameCompany,
          inn: data.iin,
          email: data.email,
          contactPhone: data.phoneNumber,
        });

        if (response) {
          toast.success("Данные успешно отправлены");
        }
      } catch (error: any) {
        fetchError(error);
      }
    }
  };

  return (
    <>
      <div className="title-block">
        Заполните форму и мы с вами свяжемся в ближайшее время
      </div>

      <form onSubmit={onSubmitForm}>
        <FormField>
          <span>Организационная структура</span>
          <Input
            name="too"
            placeholder="ТОО, ИП, Самозанятый"
            value={contractValue.too}
            onChange={(e) =>
              setContractValue({ ...contractValue, too: e.target.value })
            }
          />
        </FormField>

        <FormField>
          <span>Наименование организации</span>
          <Input
            name="nameCompany"
            placeholder="Введите название компании"
            value={contractValue.nameCompany}
            onChange={(e) =>
              setContractValue({
                ...contractValue,
                nameCompany: e.target.value,
              })
            }
          />
        </FormField>

        <FormField>
          <span>ИНН</span>
          <Input
            name="iin"
            placeholder="Введите ИНН"
            value={contractValue.iin}
            onChange={(e) =>
              setContractValue({ ...contractValue, iin: e.target.value })
            }
            onKeyPress={onKeyPress}
            minLength={0}
            maxLength={12}
          />
        </FormField>

        <FormField>
          <span>E-mail</span>
          <Input
            name="email"
            placeholder="Введите ваш E-mail"
            value={contractValue.email}
            onChange={(e) =>
              setContractValue({ ...contractValue, email: e.target.value })
            }
          />
        </FormField>

        <FormField>
          <span>Контактный телефон</span>
          <Input
            name="phoneNumber"
            placeholder="+7"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
        </FormField>

        <FormButton>
          <PrimaryButtonStyled type="submit" style={{ width: "100%" }}>
            Отправить заявку
          </PrimaryButtonStyled>
        </FormButton>
      </form>
      <ToastContainer />
    </>
  );
};

const FormField = styled.div`
  margin-bottom: 1.6rem;

  span {
    display: inline-block;
    font-size: 1.8rem;
    margin-bottom: 1.2rem;

    @media (max-width: 480px) {
      font-size: 1.3rem;
    }
  }
`;

const FormButton = styled.div`
  margin-top: 2rem;
`;

export default ContractForm;
