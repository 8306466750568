import styled from "@emotion/styled";
import AppModal from "./AppModal";
import { OutlinedButton, PrimaryButtonStyled } from "../atoms/UI/Buttons";
import { WhatsAppIcons } from "../atoms/Icons";
import useManagerModal from "../../hooks/useManagerModal";
import LoginModal from "./LoginModal";
import { Link, useNavigate } from "react-router-dom";
import { getPhoneInLocalStorage, getTokenInLocalStorage } from "../../utlis/getTokenLocal";

interface IProps {
  title: string;
  desc: string;
  clearModal: () => void;
}

const ChangeModal = ({ title, desc, clearModal }: IProps) => {
  const { modalInfo: modalInfoLogin, setLoginModal: showLoginModal } =
    useManagerModal();
  
  const navigate = useNavigate()

  const onChangeModal = (stateModals: "login") => {
    if (!getTokenInLocalStorage()) {
      if (stateModals === "login") {
        showLoginModal("Войти");
      }
    } else {
      navigate('/profile/main')
    }
  };

  return (
    <>
      {modalInfoLogin && (
        <LoginModal
          title={modalInfoLogin.title as string}
          clearModal={clearModal}
        />
      )}
      <AppModal name={"change"} onClose={clearModal}>
        <div className="title-block">{title}</div>
        <Buttons style={{width: '100%'}}>
          <PrimaryButtonStyled style={{width: '100%'}} onClick={() => onChangeModal("login")}>
            {getTokenInLocalStorage() ? getPhoneInLocalStorage() : "Войти"}
          </PrimaryButtonStyled>
        </Buttons>
        <div
          className="subtitle-block"
          style={{ marginBottom: "1.2rem", color: "#8C8C8C" }}
        >
          {desc}
        </div>

        <Link to={"https://wa.me/+996772007183"} target="_blank">
          <OutlinedButton
            background="#02447F"
            weight="600"
            style={{ width: "100%" }}
          >
            Написать в ватсап
            <WhatsAppIcons />
          </OutlinedButton>
        </Link>
      </AppModal>
    </>
  );
};

const Buttons = styled.div`
  margin-block: 1.6rem;

  button {
    width: 50%;

    padding: 1.6rem 1.2rem;
  }
`;

export default ChangeModal;
