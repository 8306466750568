import { createAsyncThunk } from "@reduxjs/toolkit";
import { assetsApi } from "../../api/assets.api";

export const getParcelsInformationThunk = createAsyncThunk(
    'getParcelsInformationThunk',
    async () => (await assetsApi.getParcelsInformation())
)

export const getParcelInoviceInformationThunk = createAsyncThunk(
    'getParcelInoviceInformationThunk',
    async (invoice: string) => (await assetsApi.getParcelsGetInvoiseInformation(invoice))
)

export const getParcelInovicePDFInformationThunk = createAsyncThunk(
    'getParcelInovicePDFInformationThunk',
    async (invoice: string) => (await assetsApi.getParcelsGetInvoisePDFInformation(invoice))
)


export const getNotificaitonsInformationThunk = createAsyncThunk(
    'getNotificaitonsInformationThunk',
    async () => (await assetsApi.getNotificationsInformation())
)